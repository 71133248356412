import React, { useState, useContext } from 'react'; // Импортируем React, useState и useContext
import { styled, alpha } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import Brightness4Icon from '@mui/icons-material/Brightness4'; // Импортируем иконку для темной темы
import Brightness7Icon from '@mui/icons-material/Brightness7'; // Импортируем иконку для светлой темы
import SVGLogo from '../static/img/SVGLogo';
import { ThemeContext } from './ThemeContext'; // Импортируем контекст темы
import { Button, Grid } from '@mui/material';
import { Link } from 'react-router-dom';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(5),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  width: '100%',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));

export default function HeadS({ data, setFilteredData }) {
  const [searchValue, setSearchValue] = useState('');
  const { toggleTheme } = useContext(ThemeContext); // Получаем функцию переключения темы из контекста
  const isDarkMode = document.body.classList.contains('dark-mode'); // Проверяем текущую тему

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchValue(value);

    // Фильтруем данные по book_num
    const filtered = data.filter(item => 
      item.book_num.toString().includes(value) // Преобразуем в строку для поиска
    );
    setFilteredData(filtered);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="open drawer"
            sx={{ mr: 3 }}
          >
          </IconButton>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'block' },
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            <a href="https://itdocumentum.com.ua"><SVGLogo /></a>
          </Typography>
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Пошук…"
              inputProps={{ 'aria-label': 'search' }}
              value={searchValue}
              onChange={handleSearchChange} // Обрабатываем изменения в поле ввода
            />
          </Search>
          {/* Добавляем Box для перемещения кнопки вправо */}
          <Box sx={{ flexGrow: 1 }} /> {/* Flex-grow для создания пустого пространства */}
          {/* Кнопка для перехода */}
            <Grid container justifyContent="space-between" alignItems="center" sx={{ padding: '1em' }}>
                <Grid item>
                    <Button
                        variant="contained"
                        color="primary"
                        component={Link}
                        to="/check-pdf" // Ссылка на отдельную страницу
                    >
                        Перевірити PDF
                    </Button>
                </Grid>
            </Grid>
          <IconButton
            size="large"
            edge="end"
            color="inherit"
            onClick={toggleTheme} // Обработчик переключения темы
          >
            {isDarkMode ? <Brightness7Icon /> : <Brightness4Icon />} {/* Иконка для переключения */}
          </IconButton>
        </Toolbar>
      </AppBar>
    </Box>
  );
}

import React, { useState } from "react";
import { Box, Button, Typography, CircularProgress, Alert, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@mui/material";
import { FileUpload } from "@mui/icons-material";
import axios from "axios";

const PdfChecker = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState(null);
  const [error, setError] = useState(null);
  const [checkedFiles, setCheckedFiles] = useState([]); // Массив для хранения проверенных файлов

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && file.type === "application/pdf") {
      setSelectedFile(file);
      setError(null); // Сбрасываем ошибку, если файл корректен
    } else {
      setSelectedFile(null);
      setError("Пожалуйста, выберите PDF-файл.");
    }
  };

  const handleFileUpload = async () => {
    if (!selectedFile) {
      setError("Выберите файл для проверки.");
      return;
    }

    setLoading(true);
    setResult(null);
    setError(null);

    const formData = new FormData();
    formData.append("file", selectedFile);

    try {
      const response = await axios.post("https://api.itdocumentum.com.ua/check-pdf", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setResult(response.data);
      console.log(response.data)

      // Добавляем новый файл с результатом в список проверенных файлов
      setCheckedFiles([
        ...checkedFiles,
        {
          name: selectedFile.name,
          status: response.data.result[0], // предполагаем, что статус в ответе (например, 1, 2, 3)
          agent: response.data.result[1] || "Відсутній", // Пример поля агента
          signature: response.data.result[2] || "Ні",
        },
      ]);
    } catch (err) {
      setError("Помилка. Спробуйте знову.");
    } finally {
      setLoading(false);
    }
  };

  const getRowColor = (status) => {
    switch (status) {
      case 1:
        return { backgroundColor: "green", color: "white" }; // Зеленый - статус 1
      case 2:
        return { backgroundColor: "yellow", color: "black" }; // Желтый - статус 2
      case 3:
        return { backgroundColor: "red", color: "white" }; // Красный - статус 3
      default:
        return {}; // Без окраски по умолчанию
    }
  };

  return (
    <Box
      sx={{
        maxWidth: 800,
        margin: "0 auto",
        textAlign: "center",
        padding: 4,
        border: "1px solid #ddd",
        borderRadius: "8px",
        backgroundColor: "#f9f9f9",
      }}
    >
      <Typography variant="h5" gutterBottom>
        Перевірка PDF-файлу
      </Typography>

      <input
        type="file"
        accept=".pdf"
        style={{ display: "none" }}
        id="upload-button"
        onChange={handleFileChange}
      />
      <label htmlFor="upload-button">
        <Button
          variant="contained"
          component="span"
          startIcon={<FileUpload />}
          sx={{ marginBottom: 2 }}
        >
          Обрати файл
        </Button>
      </label>

      {selectedFile && (
        <Typography variant="body1" gutterBottom>
          Обраний файл: {selectedFile.name}
        </Typography>
      )}

      {error && <Alert severity="error">{error}</Alert>}

      <Box sx={{ marginTop: 2 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleFileUpload}
          disabled={!selectedFile || loading}
        >
          {loading ? "Перевірка..." : "Перевірити файл"}
        </Button>
      </Box>

      {loading && <CircularProgress sx={{ marginTop: 2 }} />}

      {/* Таблица проверенных файлов */}
      <Box sx={{ marginTop: 4 }}>
        <Typography variant="h6" gutterBottom>
          Перевірені файли:
        </Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Назва</TableCell>
                <TableCell>Статус</TableCell>
                <TableCell>Агент</TableCell>
                <TableCell>Підпис</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {checkedFiles.map((file, index) => (
                <TableRow key={index} sx={getRowColor(file.status)}> {/* Используем sx вместо style */}
                  <TableCell>{file.name}</TableCell>
                  <TableCell>
                    {file.status === 1 ? "Перевірено" : file.status === 2 ? "Потрібна додаткова перевірка" : "Виправлено"}
                  </TableCell>
                  <TableCell>{file.agent}</TableCell>
                  <TableCell>{file.signature}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default PdfChecker;
